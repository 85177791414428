import ReactDOMClient from 'react-dom'
import { ErrorPageCodeEnum, ThemeEnum, InitThemeColor } from '@/constants/base'
import { onInstallForApp } from '@/helper/lifecycle'
import { onInstallForClient } from '@/helper/lifecycle/client'
import { baseCommonStore } from '@/store/common'
import { getSeo } from '@/helper/seo'
import { baseUserStore } from '@/store/user'
import { IsModuleWhiteListRoute } from '@/helper/module-config'
import AsyncSuspense from '@/components/async-suspense'
import { ErrorPage } from '@/components/error-page'
import { getRedirectUrl } from '@/helper/auth'
import { link } from '@/helper/link'
import { systemThemeColor } from '@/helper/env'
import { addThemeCss } from '@/helper/theme'
import Layout from './layout'

addThemeCss()

export const clientRouting = true
export const prefetchStaticAssets = { when: 'VIEWPORT' }
export const hydrationCanBeAborted = true

async function render(pageContext: PageContext) {
  const { Page, pageProps, needSeo, authTo, unAuthTo, path, urlParsed, routeAuth = true } = pageContext
  const redirectUrl = getRedirectUrl(authTo, unAuthTo, urlParsed.search?.go)
  const isRedirectTo = !!redirectUrl
  const { theme } = baseCommonStore.getState()
  /** 普通模式 - 判断是否模块白名单路由 */
  const isNotModuleWhiteListRouting = !IsModuleWhiteListRoute(path || '')

  let appLayout
  if (!routeAuth) {
    appLayout = (
      <Layout pageContext={pageContext}>
        <ErrorPage errorCode={ErrorPageCodeEnum.is404} />
      </Layout>
    )
  } else {
    appLayout = isNotModuleWhiteListRouting ? (
      <Layout pageContext={pageContext}>
        <ErrorPage errorCode={ErrorPageCodeEnum.is404} />
      </Layout>
    ) : needSeo ? (
      <Layout pageContext={pageContext}>{!isRedirectTo && <Page {...pageProps} />}</Layout>
    ) : (
      <Layout pageContext={pageContext}>
        <AsyncSuspense hasLoading>{!isRedirectTo && <Page {...pageProps} />}</AsyncSuspense>
      </Layout>
    )
  }

  const container = document.getElementById('page-view')!
  if (pageContext.isHydration) {
    await onInstallForApp(pageContext)

    await onInstallForClient(pageContext)

    ReactDOMClient.hydrate(appLayout, container)
    if (isRedirectTo && systemThemeColor === InitThemeColor['2z']) {
      let handleLink = redirectUrl.replace(/\/login/, function (match) {
        // eslint-disable-next-line no-template-curly-in-string
        if (match === '/login') {
          baseUserStore.getState().setLogRegisterVisible(true)
          return '/'
        }
        return match
      })
      link(handleLink, { overwriteLastHistoryEntry: true })
    }
    return
  }

  ReactDOMClient.render(appLayout, container)
  const { title, description } = getSeo(pageContext)
  document.title = title
  document?.querySelector('meta[name="description"]')?.setAttribute('content', description)
  document.body.setAttribute('theme', theme || ThemeEnum.dark)
  if (isRedirectTo && systemThemeColor === InitThemeColor['2z']) {
    let handleLink = redirectUrl.replace(/\/login|\/register/, function (match) {
      // eslint-disable-next-line no-template-curly-in-string
      if (match === '/login' || match === '/register') {
        baseUserStore.getState().setLogRegisterVisible(true)
        return '/'
      }
      return match
    })
    link(handleLink, { overwriteLastHistoryEntry: true })
  }
}

function onHydrationEnd() {}

export { render, onHydrationEnd }
