import { baseUserStore } from '@/store/user'
import { baseCommonStore } from '@/store/common'
import ws from '@/plugins/ws'
import { wsUrl } from '../../env'

export async function initWS() {
  ws.setOptions({
    wsUrl,
    success() {
      if (baseUserStore.getState().isLogin) {
        ws.login()
      }
    },
    getToken: () => {
      return baseUserStore.getState().token?.accessToken as unknown as string
    },
  })
  ws.connect()

  ws.onAddWsDelayTimeChange(time => {
    baseCommonStore.getState().setwsDelayTime(time)
  })
}

baseUserStore.subscribe(
  userState => userState.isLogin,
  () => {
    if (baseUserStore.getState().isLogin) {
      ws.login()
    } else {
      ws.logout()
    }
  }
)
