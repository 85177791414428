import { useState, useEffect } from 'react'
import { Button, Modal, Drawer, Tooltip } from '@nbit/arco'
import UserPopUp from '@/features/user/components/popup'
import UserPersonalCenterAboutUs from '@/features/user/personal-center/about-us'
import UserPersonalCenterMenuNavigation from '@/features/user/personal-center/menu-navigation'
import { useCommonStore } from '@/store/common'
import { useLayoutStore } from '@/store/layout'
import { t } from '@lingui/macro'
import MessageCenter from '@/features/message-center'
import { useUserStore } from '@/store/user'
import Icon from '@/components/icon'
import ThemeSwitch from '@/components/theme-switch'
import DownloadIcon from '@/components/download-icon'
import { envIsServer } from '@/helper/env'
import { getMergeModeStatus } from '@/features/user/utils/common'
import { useGuidePageInfo } from '@/hooks/features/layout'
import { getGuidePageComponentInfoByKey } from '@/helper/layout'
import { link } from '@/helper/link'
import ShouldGuidePageComponentDisplay from '@/features/recreation/component/component-should-display'
import Wallet from '@/features/recreation/theme/ok/layout/recreation-header/components/wallet'
import I18nSelect from '@/features/recreation/component/i18n-select'
import DrawerContent from '@/features/recreation/theme/ok/layout/recreation-header/components/drawer-content'
import { showLocaleInfoContent } from '@/constants/common'
import styles from './style.module.css'

interface IPersonalization {
  isTrade?: boolean
}
function Personalization(props: IPersonalization) {
  const [visibleAboutUs, setVisibleAboutUs] = useState<boolean>(false)
  const userStore = useUserStore()
  const isMergeMode = getMergeModeStatus()
  const { localeInfo } = useCommonStore()

  const { pageInfoTopBar = [] } = useGuidePageInfo()

  const styleSwitchIcon = getGuidePageComponentInfoByKey('styleSwitchIcon', pageInfoTopBar)
  const language = getGuidePageComponentInfoByKey('language', pageInfoTopBar)

  const config = {
    title: <div>{t`features/layout/components/personalization-0`}</div>,
    content: <I18nSelect />,
    footer: null,
    icon: null,
    closable: true,
  }

  const { getMerchantTrialQualification, setTrialAccountInfo, hasMerchantTrialQualification } = useUserStore()

  // 是否窄屏，小于 1200 为窄屏
  const { narrowScreen } = useLayoutStore()

  // 是否显示右侧抽屉
  const [showRightDrawer, setShowRightDrawer] = useState(false)

  useEffect(() => {
    !userStore?.isLogin && getMerchantTrialQualification()
  }, [userStore?.isLogin])

  const openI18nSelect = () => {
    Modal.info!(config)
  }
  // 登录和非登录下在服务端和客户端的结果不同，即使加上 key 也无法让客户端接管时重新渲染，所以这里直接返回空
  if (envIsServer) {
    return <div className="personalization-wrap"></div>
  }
  return (
    <div className="personalization-wrap" style={{ visibility: showRightDrawer ? 'hidden' : 'visible' }}>
      {userStore?.isLogin ? (
        <>
          {/* <UserAssetsMenuNavigation /> */}
          <Wallet />
          <div className="login-btn-wrap text-wrap flex items-center">
            <UserPersonalCenterMenuNavigation isAboutShow={visibleAboutUs} handleAboutPopUpShow={setVisibleAboutUs} />
          </div>
          <span className="login-btn-wrap-line" />
          {!isMergeMode && !narrowScreen && (
            <div className="login-btn-wrap text-wrap flex items-center">
              <MessageCenter />
            </div>
          )}
        </>
      ) : !isMergeMode ? (
        <>
          <div className="login-btn-wrap text-wrap login-btn" onClick={() => link('/login')}>
            {t`user.field.reuse_07`}
          </div>

          <div className="login-btn-wrap text-wrap">
            <Button
              className="register-btn"
              type="primary"
              size="small"
              onClick={() => link('/register')}
            >{t`user.common.register`}</Button>
          </div>
          {hasMerchantTrialQualification && (
            <div className="login-btn-wrap text-wrap">
              <Button
                size="small"
                type="outline"
                className={'try-btn'}
                onClick={() => setTrialAccountInfo()}
              >{t`features_user_register_index_wvdg2uy5cw`}</Button>
            </div>
          )}
        </>
      ) : null}
      {!isMergeMode && !narrowScreen && showLocaleInfoContent(localeInfo?.showDownloadView) && (
        <div className="login-btn-wrap text-wrap">
          <Tooltip className={styles['tooltip-wrap']} content={t`Download`}>
            <div>
              <DownloadIcon />
            </div>
          </Tooltip>
        </div>
      )}
      <ShouldGuidePageComponentDisplay {...styleSwitchIcon}>
        <div className="login-btn-wrap text-wrap">
          <ThemeSwitch />
        </div>
      </ShouldGuidePageComponentDisplay>
      {!narrowScreen && (
        <ShouldGuidePageComponentDisplay {...language}>
          <div className="login-btn-wrap text-wrap" onClick={openI18nSelect}>
            <Tooltip
              className={styles['tooltip-wrap']}
              content={t`features/layout/components/personalization-0`}
              position="br"
            >
              <div>
                <Icon name="nav_language" fontSize={20} hasTheme />
              </div>
            </Tooltip>
          </div>
        </ShouldGuidePageComponentDisplay>
      )}

      {narrowScreen && (
        <div className="login-btn-wrap text-wrap" onClick={() => setShowRightDrawer(true)}>
          <Icon name="icon_more" className="text-xl" />
        </div>
      )}

      <Drawer
        width={260}
        visible={showRightDrawer}
        onOk={() => {
          setShowRightDrawer(false)
        }}
        onCancel={() => {
          setShowRightDrawer(false)
        }}
        footer={null}
        className={styles['header-drawer-wrap']}
      >
        <DrawerContent openI18nSelect={openI18nSelect} />
      </Drawer>

      <UserPopUp
        className="user-popup"
        title={<div style={{ textAlign: 'left' }}>{userStore.userTransitionDatas?.homeColumnName || ''}</div>}
        visible={visibleAboutUs}
        closeIcon={<Icon name="close" hasTheme />}
        onCancel={() => setVisibleAboutUs(false)}
        footer={null}
      >
        <UserPersonalCenterAboutUs />
      </UserPopUp>
    </div>
  )
}

export default Personalization
