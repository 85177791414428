import Icon from '@/components/icon'
import { t } from '@lingui/macro'
import { getMergeModeStatus } from '@/features/user/utils/common'
import { getAuthModuleRoutes, getOrdersModuleStatus } from '@/helper/module-config'
import { getMyBetsRoutePath } from '@/helper/route/game'
import { EntertainmentArea } from '@/helper/route/entertainment'
import { useRaSettingsStore } from '@/store/settings'
import { WalletModeEnum } from '@/constants/settings'
import { MenuNavigation } from './base'
import styles from './index.module.css'

function UserMenuNavigation() {
  const isMergeMode = getMergeModeStatus()

  const {
    raSettings: { walletModeCd },
  } = useRaSettingsStore()

  const assetOverview = {
    key: 1,
    icon: <Icon name="assets_selected" />,
    text: t`assets.index.title`,
    subText: '',
    isLink: true,
    link: '/assets',
    isShow: true,
  }

  const recreation = {
    key: 7,
    icon: <Icon name="yule_zichang" className={styles['recretion-icon']} />,
    text: t`features_assets_assets_layout_4zvduzihqy`,
    subText: '',
    isLink: true,
    link: '/assets/recreation',
    isShow: true,
  }

  const defaultAssetMenu = [assetOverview, recreation]
  const assetsMenuList = isMergeMode
    ? defaultAssetMenu
    : getAuthModuleRoutes({
        assetOverview,
      })
  const newAssetsMenuList = assetsMenuList.filter(item => item.isShow)

  const myBet = {
    key: 5,
    icon: <Icon name="icon_revision_bets" />,
    text: t`features_home_my_bet_index_avwmsadjdl`,
    subText: '',
    isLink: true,
    link: getMyBetsRoutePath(),
    isShow: true,
  }

  const myReturnWater = {
    key: 6,
    icon: <Icon name="contract_selected" />,
    text: t`features_home_component_switch_router_index_qf1bqguow0`,
    subText: '',
    isLink: true,
    link: EntertainmentArea().myRebate,
    isShow: true,
  }

  const transferRecord = {
    key: 7,
    icon: <Icon name="asset_icon_record" />,
    text: t`features/assets/main/financial-record/index-2`,
    subText: '',
    isLink: true,
    link: EntertainmentArea().transferRecords,
    isShow: walletModeCd !== WalletModeEnum.single,
  }

  const statementStatistics = {
    key: 8,
    icon: <Icon name="icon_report_statistics_sidebar" />,
    text: t`features_home_component_switch_router_index_55a8dpaxkh`,
    subText: '',
    isLink: true,
    link: EntertainmentArea().reportRecords,
    isShow: true,
  }

  const orderMenuList = getAuthModuleRoutes({
    myBet,
    myReturnWater,
    transferRecord,
    statementStatistics,
  })

  const newOrderMenuList = orderMenuList.filter(item => item.isShow)

  const assetsMenu = {
    name: t`assets.index.overview.menuName`,
    list: newAssetsMenuList,
  }

  const orderMenu = {
    name: t`features/assets/c2c/total-assets/index-0`,
    list: newOrderMenuList,
  }

  let menus = [assetsMenu]
  const isShowOrder = getOrdersModuleStatus()
  isShowOrder && menus.push(orderMenu)

  return (
    <div className="flex">
      {menus.map((menu, index) => (
        <MenuNavigation key={index} name={menu.name} menuList={menu.list} />
      ))}
    </div>
  )
}

export default UserMenuNavigation
