import { useLayoutStore } from '@/store/layout'
import { useEffect } from 'react'
import { link } from '@/helper/link'
import { businessId } from '@/helper/env'
import { useCommonStore } from '@/store/common'
import { useGuidePageInfo } from '@/hooks/features/layout'
import { getGuidePageComponentInfoByKey, getHomePageConfig } from '@/helper/layout'
import { useGameStore } from '@/store/game'
import { getBasicWebApiData } from '@/apis/layout'
import { MaintenanceEnum } from '@/constants/maintenance'
import { usePageContext } from '@/hooks/use-page-context'
import { maintenancePath, getGameLobbyRoutePath } from '@/helper/route/game'
import { extractHeaderData } from '@/helper/layout/header'
import { getLangCache, getCacheLayoutHeader } from '@/helper/cache'
import Wallet from '@/features/layout/recreation-header/components/wallet'
import { useUserStore } from '@/store/user'
import cs from 'classnames'
import { ThemeEnum } from '@/constants/base'
import ShouldGuidePageComponentDisplay from '@/features/recreation/component/component-should-display'
import Styles from './header.module.css'
import Personalization from './components/personalization'
import Logo from './components/logo'

function Header() {
  const pageContext = usePageContext()
  const cacheLayoutHeader = getCacheLayoutHeader()
  const { maintenance, setMaintenanceStatus } = useGameStore()

  const guidePage = useGuidePageInfo()
  const formattedLandingPageSection = getHomePageConfig(guidePage)
  const { pageInfoTopBar = [] } = guidePage
  const { pageInfoTopBar: headerConfig } = formattedLandingPageSection || {}
  const homeIcon = getGuidePageComponentInfoByKey('homeIcon', pageInfoTopBar)
  const userStore = useUserStore()

  const langCache = getLangCache()
  const { locale, setIsRouterRecreation, theme } = useCommonStore()
  const { headerData, setHeaderData } = useLayoutStore()
  const handleLogo = async () => {
    if (!cacheLayoutHeader?.imgWebLogo) {
      const params = {
        businessId,
        lanType: locale || langCache,
      }
      const { data, isOk } = await getBasicWebApiData(params)
      if (!isOk && !data) return
      const newData = { ...data } as any
      const header = extractHeaderData(newData)
      setHeaderData(header)
    }
  }

  const isDark = theme === ThemeEnum.dark

  const handleGameRouter = () => {
    const path = pageContext?.path
    if (maintenance === MaintenanceEnum.normal) {
      const isGame = path === maintenancePath()
      isGame && link(getGameLobbyRoutePath())
      return
    }
    return link(maintenancePath())
  }

  useEffect(() => {
    handleGameRouter()
  }, [maintenance])

  useEffect(() => {
    handleLogo()
    setMaintenanceStatus()
    setIsRouterRecreation(true)
  }, [])

  return (
    <ShouldGuidePageComponentDisplay {...headerConfig}>
      <div className={cs(Styles.scoped, { 'is-dark': isDark })}>
        <div className="header-content">
          <div className="header-left">
            <div className="home-wrap">
              <ShouldGuidePageComponentDisplay {...homeIcon}>
                <Logo data={headerData} />
              </ShouldGuidePageComponentDisplay>
            </div>
          </div>
          <div className="center-wallet">{userStore?.isLogin && <Wallet hiddenMenus />}</div>
          <Personalization />
        </div>
      </div>
    </ShouldGuidePageComponentDisplay>
  )
}

export default Header
